<template>
  <div class="ob-ed">
    <OnboardingLayout :step="currentStep">
      <template v-slot:content-section>
        <div class="ob-ed__new w-100">
          <div class="ob-ed__main">
            <div class="ob-img__responsive_view">
              <img src="@/assets/images/onboarding/news.svg" alt="news" />
            </div>
            <h2 class="ob-ed__heading">
              {{ $t("onboarding.experience.heading") }}
            </h2>
            <div class="ob-main__sec">
              <b-form @submit="onNext">
                <ValidationObserver v-slot="{ invalid }">
                  <b-row class="ob-sec__dropdown">
                    <b-col cols sm="12" md="6" class="ob-responsive__view">
                      <ValidationProvider
                        name="Health"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <LxpDropdown
                          :key="key"
                          :label="$t('onboarding.experience.background.label')"
                          :placeholder="
                            $t('onboarding.experience.background.label')
                          "
                          :dropdownArr="validExperiences"
                          :value="
                            form.experience ? form.experience.name : undefined
                          "
                          @click="onSelect($event, 'experience')"
                          :isFloating="true"
                        />
                        <span class="error mb-2">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-col>
                    <b-col
                      cols
                      sm="12"
                      md="6"
                      v-if="showRole"
                      class="ob-responsive__view"
                    >
                      <ValidationProvider
                        name="Role"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <LxpDropdown
                          :key="key"
                          :label="$t('onboarding.experience.role.label')"
                          :placeholder="$t('onboarding.experience.role.label')"
                          :dropdownArr="validRoles"
                          :value="form.role ? form.role.name : undefined"
                          @click="onSelect($event, 'role')"
                          :isFloating="true"
                        />
                        <span class="error mb-2">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <Footer
                    :invalid="invalid"
                    ref="footer"
                    :buttonText="$t('general.next')"
                  />
                </ValidationObserver>
              </b-form>
            </div>
          </div>
        </div>
      </template>
    </OnboardingLayout>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import OnboardingLayout from "../Layout/OnboardingLayout";
import OnboardingMixin from "@/components/Onboarding/mixin";
import Footer from "@/components/Layout/OnboardingPageLayout/Footer.vue";
import { LxpDropdown } from "didactica";

export default {
  mixins: [OnboardingMixin],
  components: { OnboardingLayout, LxpDropdown, Footer },
  data() {
    return {
      key: 0,
      isCompleted: false,
      form: {
        experience: "",
        role: ""
      },
      currentStep: 0,
      showRole: false
    };
  },
  computed: {
    ...mapGetters(["roles", "experience", "allProfile"]),
    validExperiences() {
      return this.experience.filter(exp => exp.valid === true);
    },
    validRoles() {
      return this.roles.filter(exp => exp.valid === true);
    }
  },
  mounted() {
    this.currentStep = this.step;
    this.initData();
  },
  methods: {
    initData() {
      const profile = this.allProfile;
      if (profile) {
        if (profile.experience) {
          this.form.experience = profile.experience;
          this.showHideRole(profile.experience.id);
          this.setNextFocus();
        }
        if (profile.role) {
          this.form.role = profile.role;
        }
        this.key++;
      }
    },
    showHideRole(id) {
      if (id === 4 || id === 7) {
        this.showRole = true;
      } else {
        this.form.role = "";
        this.showRole = false;
      }
    },
    onNext(event) {
      event.preventDefault();
      let obj = {
        experience:
          this.form.experience !== "" ? this.form.experience.id : null,
        role: this.form.role !== "" ? this.form.role.id : null
      };
      this.updateOnboarding(obj, this.form);
    },
    setNextFocus() {
      // set the focus to next button for enter key to work
      setTimeout(() => {
        this.$refs.footer.setNextFocus();
      }, 100);
    },
    onSelect(e, type) {
      this.form[type] = e;
      if (type === "experience") {
        this.showHideRole(e.id);
      }
      this.setNextFocus();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/onboarding/_onboarding.scss";
</style>
